<template>
    <div class="p-4 pt-10 md:pt-16">
        <div class="bg-white border border-innerBorder rounded-2xl p-4 lg:p-8 md:w-2/3 xl:w-1/2 mx-auto">
            <p class="font-bold text-2xl mb-6">Création de compte</p>
            <RegisterForm/>  
        </div>
        <div class="my-4 px-2 font-bold text-sm sm:text-base text-center">
            <p>Vous avez déjà un compte <router-link to="/" class="text-blueLink underline">Se Connecter</router-link></p>
        </div>
    </div>
</template>

<script>
import RegisterForm from './forms/RegisterForm'
export default {
    data() {
        return {
            
        }
    },
    components: {
        RegisterForm
    },
    computed: {
        
    },
    methods: {
        
    },
}
</script>

<style scoped>

</style>